import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { useGetUserSubscriptionQuery } from 'store/api/usersApi'
import { CONFIG } from '../../constants'
import { getAuthToken } from '../../store/slices/authSlice'

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        authToken: null,
        tier: null,
        userId: '',
    },
    reducers: {
        clearAuthState: (state) => {
            state.authToken = null
        },
        addAuthState: (state, action) => {
            state.authToken = action.payload
        },
        addUserId: (state, action) => {
            state.userId = action.payload
        },
    },
})

export const { addAuthState, clearAuthState, addUserId } = authSlice.actions

const selectAuth = (state) => state.auth

export const selectAuthToken = createSelector(
    [selectAuth],
    (auth) => auth.authToken,
)

export const selectUserId = createSelector([selectAuth], (auth) => auth.userId)

export const selectFeatureFlags = createSelector([selectUserId], (userId) => {
    const featureFlagUserIds = CONFIG.FEATURE_FLAGS.COLOR_MANAGEMENT_PRESETS
    return {
        canAccessColorManagementPresets: featureFlagUserIds.includes(userId),
    }
})

export default authSlice.reducer
